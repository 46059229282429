import '@capacitor-community/http';
import { IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonListHeader, IonNote, IonPage, IonTitle, IonToggle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { albums, analytics, barcode, car, card, exit, help, information, informationCircle, informationCircleOutline, lockClosed, person, qrCode, statsChart, thumbsUp, tv, tvOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deslogar, requestService } from '../../../../../Utils/Services';
import './Conta.css';

const Conta: React.FC = () => {

    const history = useHistory();
    const [showToast2, setShowToast2] = useState(false);

    useIonViewDidEnter(() => {
        document.getElementsByTagName("ion-tab-bar")[0].style.display = "flex";
    });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={person} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{localStorage.getItem("nome") || "Conta"}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="conta">
                <IonListHeader lines="full">Operações</IonListHeader>
                <IonItem lines="none" button routerLink="/cliente/contaalt" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={informationCircle} />
                    <IonLabel>Instruções ao motorista</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/instrucoes" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={thumbsUp} />
                    <IonLabel>Benefícios ao motorista</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/qrcode" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={qrCode} />
                    <IonLabel>QR Code para motoristas</IonLabel>
                </IonItem>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/relatorio" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={statsChart} />
                    <IonLabel>Relatório Analítico</IonLabel>
                </IonItem>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/relatorioboleto" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={barcode} />
                    <IonLabel>Relatório Boletos</IonLabel>
                </IonItem>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/painel" routerDirection="forward" detail>
                    <IonIcon color='medium' slot="start" icon={analytics} />
                    <IonLabel>Painel</IonLabel>
                </IonItem>
                <IonItem hidden={!((localStorage.getItem("perfil") || "") === "Cliente")} lines="none" button routerLink="/cliente/monitor" routerDirection="forward" detail>
                    <IonIcon color='medium' slot="start" icon={tvOutline} />
                    <IonLabel>Monitor</IonLabel>
                </IonItem>
                <IonItem lines="none" hidden={true}>
                    <IonIcon color='medium' slot="start" icon={tv} />
                    <IonLabel>Nova tela de operações</IonLabel>
                    <IonToggle checked={localStorage.getItem("operacoesNovo") == '1' ? true : false} onIonChange={(e) => { e.detail.checked ? window.localStorage.setItem("operacoesNovo", '1') : window.localStorage.setItem("operacoesNovo", '0'); window.location.reload(); }} />
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/instrucoes" routerDirection="forward" onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={car} />
                    <IonLabel>Veículos</IonLabel>
                </IonItem>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={statsChart} />
                    <IonLabel>Relatórios</IonLabel>
                </IonItem>
                <IonListHeader lines="full" hidden={true}>Assinatura</IonListHeader>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={card} />
                    <IonLabel>Forma de pagamento</IonLabel>
                </IonItem>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={albums} />
                    <IonLabel>Histórico de pagamentos</IonLabel>
                </IonItem>
                <IonListHeader lines="full">Informações</IonListHeader>
                <IonItem lines="none" button onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={help} />
                    <IonLabel>Ajuda</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/sobre" routerDirection="forward" onClick={() => { }} detail hidden={true}>
                    <IonIcon color='medium' slot="start" icon={information} />
                    <IonLabel>Sobre</IonLabel>
                </IonItem>
                <IonItem lines="none" button routerLink="/cliente/termospolitica" routerDirection="forward" onClick={() => { }} detail>
                    <IonIcon color='medium' slot="start" icon={lockClosed} />
                    <IonLabel>Privacidade e termos de uso</IonLabel>
                </IonItem>
                <IonItem lines="none" button onClick={() => { setShowToast2(true); }} >
                    <IonIcon color='medium' slot="start" icon={exit} />
                    <IonLabel>Sair</IonLabel>
                    <IonAlert
                        isOpen={showToast2}
                        onDidDismiss={() => setShowToast2(false)}
                        cssClass='my-custom-class'
                        header={'Sair do Saidamax'}
                        message={'Deseja realmente <strong>desconectar</strong> de sua conta?'}
                        buttons={[
                            {
                                text: 'Cancelar',
                                role: 'cancel',
                            },
                            {
                                text: 'Sair',
                                cssClass: 'primary',
                                handler: () => {
                                    requestService(deslogar.url, {
                                        method: deslogar.method,
                                        headers: [["token", localStorage.getItem("token") || ""]],
                                        credentials: 'include'
                                    },
                                        history,
                                        (response: any) => {
                                            localStorage.clear();
                                            history.replace("/login/");
                                        },
                                        (error: any) => {
                                            localStorage.clear();
                                            history.replace("/login/");
                                        });
                                }
                            }
                        ]}
                    />
                </IonItem>
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonItem lines="none">
                    <IonIcon color='medium' slot="start" icon={informationCircleOutline} />
                    <IonLabel>Versão</IonLabel>
                    <IonNote slot="end" className="footeritemnote">1.20.0</IonNote>
                </IonItem>
            </IonFooter>
        </IonPage>
    );
};

export default Conta;
