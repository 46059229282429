import { IonAlert, IonAvatar, IonBadge, IonButton, IonChip, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonNote, IonRow, IonSegmentButton, IonText, useIonAlert } from '@ionic/react';
import { car, card, documentText, ellipse, handRight, megaphone, pause, pauseCircle, pencil, person, playCircle, power, sync } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { urlBase, operacaoLiberarOperacao, operacaoAtualizarSemaforo, operacaoRegistrarAnotacao, operacaoChamarMotorista, encerrarOperacao } from '../../../../../Utils/Services';
import PropTypes from 'prop-types'

class OperacoesList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            ret: { Items: [] },
            filtro: "",
            lojas: "",
            lastEvaluatedKey: null,
            showAlert: false,
            indice: null
        };
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.changeSemaforo = this.changeSemaforo.bind(this);
        this.changeLiberacao = this.changeLiberacao.bind(this);
        this.registrarAnotacao = this.registrarAnotacao.bind(this);
        this.registrarChamada = this.registrarChamada.bind(this);
        this.registrarEncerramento = this.registrarEncerramento.bind(this);
    }

    async changeSemaforo(indice: any, semaforo: any) {
        let obj = [...this.state.ret.Items];
        obj[indice].atualizandoSemaforo = true;
        this.setState((state: any) => ({ ...state, [state.ret.Items]: [...obj] }));

        let objBefore = [...this.state.ret.Items];

        let result = await this.updateSemaforo(obj[indice].pk, semaforo);
        if (result) {
            obj[indice].atualizandoSemaforo = false;
            obj[indice].semaforo = semaforo;
            setTimeout(() => { this.setState((state: any) => ({ ...state, [state.ret.Items]: [...obj] })); }, 500);
        } else {
            objBefore[indice].atualizandoSemaforo = false;
            setTimeout(() => this.setState((state: any) => ({ ...state, [state.ret.Items]: [...objBefore] })), 500);
        }
    }

    async changeLiberacao(indice: any, liberado: any) {
        let obj = [...this.state.ret.Items];
        let anotacoes = obj[indice].anotacoes;
        obj[indice].atualizandoLiberacao = true;
        obj[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: 'Incluindo...' }]
        this.setState((state: any) => ({ ...state, [state.ret.Items]: [...obj] }));

        let objBefore = [...this.state.ret.Items];

        let result = await this.updateLiberacaoService(obj[indice].pk, liberado);
        if (result) {
            obj[indice].atualizandoLiberacao = false;
            obj[indice].liberado = liberado;
            obj[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: liberado == true ? "Operação liberada" : "Operação em liberação", pessoa: 'você' }];
            setTimeout(() => { this.setState((state: any) => ({ ...state, [state.ret.Items]: [...obj] })); }, 500);
        } else {
            objBefore[indice].atualizandoLiberacao = false;
            objBefore[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: 'Erro na inserção. Tente novamente.', cor: 'var(--ion-color-danger)' }];
            setTimeout(() => this.setState((state: any) => ({ ...state, [state.ret.Items]: [...objBefore] })), 500);
        }
    }

    async registrarAnotacao(indice: any) {
        this.setState({ indice: indice, showAlertAnotacao: true });
    }

    async registrarChamada(indice: any) {
        this.setState({ indice: indice, showAlertChamada: true });
    }

    async registrarEncerramento(indice: any) {
        this.setState({ indice: indice, showAlertEncerramento: true });
    }

    render() {
        const { loading, ret } = this.state;
        return loading ? <div style={{ width: '100%', textAlign: 'center' }}><br></br>Carregando...</div> : (
            <>
                <OperacoesCards items={ret || []} router={this.props.router} loadMoreItems={this.loadMoreItems} changeSemaforo={this.changeSemaforo} usaLiberacao={this.props.usaLiberacao} changeLiberacao={this.changeLiberacao} registrarAnotacao={this.registrarAnotacao} registrarChamada={this.registrarChamada} registrarEncerramento={this.registrarEncerramento} pesquisa={this.props.pesquisa} chipEmLiberacao={this.props.chipEmLiberacao} chipLiberado={this.props.chipLiberado} />
                <IonAlert
                    isOpen={this.state.showAlertAnotacao}
                    onDidDismiss={() => this.setState((state: any) => ({ ...state, showAlertAnotacao: false }))}
                    cssClass="my-custom-class"
                    header={'Registrar anotação na operação'}
                    message={'Digite a anotação abaixo'}
                    backdropDismiss={false}
                    inputs={[
                        {
                            name: 'mensagem',
                            type: 'textarea',
                            id: 'name2-id',
                            placeholder: 'Digite aqui a anotação',
                        }]}
                    buttons={['Cancel', {
                        text: 'Incluir', handler: async (d) => {
                            if (!d.mensagem || d.mensagem.trim() == "") {
                                return null;
                            }

                            const indice = this.state.indice;
                            let items = [...this.state.ret.Items];
                            let anotacoes = items[indice].anotacoes;
                            items[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: 'Incluindo...' }]
                            this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));

                            let result = await this.postAnotacao(items[indice].pk, d.mensagem);
                            if (result) {
                                //items[indice].anotacoes = [...items[indice].anotacoes, { data: new Date().toISOString(), anotacao: d.mensagem }];
                                setTimeout(() => {
                                    items[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: d.mensagem }];
                                    this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));
                                }, 1000);
                            }
                            else {
                                items[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: 'Erro na inserção...', cor: 'var(--ion-color-danger)' }];
                                this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));
                                setTimeout(() => {
                                    items[indice].anotacoes = [...anotacoes];
                                    this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));
                                }, 5000);
                            }
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.showAlertChamada}
                    onDidDismiss={() => this.setState((state: any) => ({ ...state, showAlertChamada: false }))}
                    cssClass="my-custom-class"
                    header={'Enviar mensagem ao motorista'}
                    message={'Digite a mensagem abaixo'}
                    backdropDismiss={false}
                    inputs={[
                        {
                            name: 'mensagem',
                            type: 'textarea',
                            id: 'name2-id',
                            value: 'Hora de descarregar venha até a doca.',
                            placeholder: 'Digite a mensagem',
                        }]}
                    buttons={['Cancel', {
                        text: 'Ok', handler: async (d) => {
                            if (!d.mensagem || d.mensagem.trim() == "") {
                                return null;
                            }

                            const indice = this.state.indice;
                            let items = [...this.state.ret.Items];
                            let anotacoes = items[indice].anotacoes;
                            items[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: 'Incluindo...' }]
                            this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));

                            let result = await this.postChamada(items[indice].pk, d.mensagem);
                            if (result) {
                                //items[indice].anotacoes = [...items[indice].anotacoes, { data: new Date().toISOString(), anotacao: d.mensagem }];
                                setTimeout(() => {
                                    items[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: d.mensagem }];
                                    this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));
                                }, 1000);
                            }
                            else {
                                items[indice].anotacoes = [...anotacoes, { data: new Date().toISOString(), anotacao: 'Erro na inserção...', cor: 'var(--ion-color-danger)' }];
                                this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));
                                setTimeout(() => {
                                    items[indice].anotacoes = [...anotacoes];
                                    this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertAnotacao: false }));
                                }, 5000);
                            }
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.showAlertEncerramento}
                    onDidDismiss={() => this.setState((state: any) => ({ ...state, showAlertEncerramento: false }))}
                    cssClass="my-custom-class"
                    header={'Encerrar operação'}
                    message={'Realizar <strong>encerramento</strong>?'}
                    backdropDismiss={false}
                    buttons={[{
                        text: 'Cancelar',
                        role: 'cancel',
                    }, {
                        text: 'Confirmar',
                        cssClass: 'primary',
                        handler: async () => {
                            console.log('encerrar');
                            const indice = this.state.indice;
                            let items = [...this.state.ret.Items];
                            items[indice].divEncerrando = true;
                            this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertEncerramento: false }));

                            let result = await this.putEncerramento(items[indice].pk);
                            if (result) {
                                setTimeout(() => {
                                    items[indice].divEncerrado = true;
                                    this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertEncerramento: false }));
                                }, 1000);
                            } else {
                                items[indice].divEncerrando = false;
                                this.setState((state: any) => ({ ...state, [state.ret.Items]: [...items], showAlertEncerramento: false }));
                            }
                        }
                    }]}
                />
            </>);
    }

    loadItems(filtro: any, lojas: any, ordenar: boolean) {
        let token: string = "";
        const tkn = localStorage.getItem("token");
        if (typeof (tkn) === "string") {
            token = tkn;
        }

        fetch(urlBase.url + "/operacoes?filtro=" + filtro + "&lojas=" + lojas + "&ordenar=" + ordenar,
            {
                method: urlBase.method,
                headers: [
                    ["token", token]
                ],
                credentials: 'include'
            }).then((resp: Response) => {
                if (resp.status == 401) {
                    this.props.router.push("../login");
                }
                else {
                    resp.json().then((res: any) => {

                        //const myNewArray = res.Items.map((item:any, index:any) =>  { return {"number" :index, ...item} } );
                        res.Items.map((item: any) => {
                            let notasFiltro = JSON.stringify(item.notasAdicionais);
                            let liberado;
                            if (item.hasOwnProperty('liberado')) {
                                liberado = item.liberado;
                            }
                            else {
                                liberado = false;
                            }

                            // New properties to be added
                            const newPropsObj = {
                                notasFiltro: notasFiltro,
                                liberado: liberado
                            };

                            return Object.assign(item, newPropsObj);
                        })

                        this.setState({ loading: false, ret: res, filtro: filtro, lojas: lojas, lastEvaluatedKey: res.LastEvaluatedKey });
                    }).catch((e: any) => console.log(e));
                }
            }).catch((e: any) => { console.log(e); this.props.router.push("../login") });
    }

    loadMoreItems() {
        if (this.state.lastEvaluatedKey &&
            this.state.lastEvaluatedKey.hasOwnProperty("gsi2sk") &&
            this.state.lastEvaluatedKey.hasOwnProperty("pk") &&
            this.state.lastEvaluatedKey.hasOwnProperty("sk")) {

            let token: string = "";
            const tkn = localStorage.getItem("token");
            if (typeof (tkn) === "string") {
                token = tkn;
            }

            fetch(urlBase.url + "/operacoes?filtro=" + this.state.filtro + "&isk=" + this.state.lastEvaluatedKey.gsi2sk + "&pk=" + this.state.lastEvaluatedKey.pk + "&sk=" + this.state.lastEvaluatedKey.sk + "&lojas=" + this.state.lojas,
                {
                    method: urlBase.method,
                    headers: [
                        ["token", token]
                    ],
                    credentials: 'include'
                }).then((resp: Response) => {
                    resp.json().then((operacoes: any) => {

                        operacoes.Items.map((item: any) => {
                            let notasFiltro = JSON.stringify(item.notasAdicionais);
                            return { 'notasFiltro': notasFiltro, ...item }
                        });

                        this.setState((state: any) => ({ ret: { Items: [...state.ret.Items, ...operacoes.Items], LastEvaluatedKey: operacoes.LastEvaluatedKey || undefined }, lastEvaluatedKey: operacoes.LastEvaluatedKey }));
                    })
                })
                .catch(err => console.error(err));
        }
    }

    componentDidMount() {
        this.loadItems(this.props.filtro, this.props.lojas, this.props.ordenar);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.filtro != this.props.filtro || prevProps.lojas != this.props.lojas || prevProps.reload != this.props.reload || prevProps.ordenar != this.props.ordenar) {
            this.setState({ loading: true });
            this.loadItems(this.props.filtro, this.props.lojas, this.props.ordenar);
        }
    }

    async updateLiberacaoService(pk: string, liberado: boolean) {
        let result = false;
        let objeto = { "liberado": liberado };

        await fetch(operacaoLiberarOperacao.url.replace('{pk}', pk).replace(/#/g, '%23'),
            {
                method: operacaoLiberarOperacao.method,
                headers: [
                    ["token", localStorage.getItem("token") || ""]
                ],
                body: JSON.stringify(objeto),
                credentials: 'include'
            }).then((resp: Response) => {
                if (resp.status == 200) result = true;
            });
        return result;
    }

    async updateSemaforo(pk: any, semaforo: number) {
        let result = false;
        let objeto = { semaforo: semaforo };

        await fetch(operacaoAtualizarSemaforo.url.replace("{pk}", pk.replace(/#/g, '%23')),
            {
                method: operacaoAtualizarSemaforo.method,
                headers: [
                    ["token", localStorage.getItem("token") || ""]
                ],
                body: JSON.stringify(objeto),
                credentials: 'include'
            }).then((resp: Response) => {
                if (resp.status == 200) result = true;
            });
        return result;
    }

    async postAnotacao(pk: any, mensagem: any) {
        let result = false;
        let objeto = { mensagem: mensagem }

        await fetch(operacaoRegistrarAnotacao.url.replace("{pk}", pk.replace(/#/g, '%23')),
            {
                method: operacaoRegistrarAnotacao.method,
                headers: [
                    ["token", localStorage.getItem("token") || ""]
                ],
                body: JSON.stringify(objeto),
                credentials: 'include'
            }).then((resp: Response) => {
                if (resp.status == 200) result = true;
            });
        return result;
    }

    async postChamada(pk: any, mensagem: any) {
        let result = false;
        let objeto = { mensagem: mensagem }

        await fetch(operacaoChamarMotorista.url.replace("{pk}", pk.replace(/#/g, '%23')),
            {
                method: operacaoChamarMotorista.method,
                headers: [
                    ["token", localStorage.getItem("token") || ""]
                ],
                body: JSON.stringify(objeto),
                credentials: 'include'
            }).then((resp: Response) => {
                if (resp.status == 200) result = true;
            });
        return result;
    }

    async putEncerramento(pk: any) {
        let result = false;

        await fetch(encerrarOperacao.url.replace("{id}", pk.replace(/#/g, '%23')),
            {
                method: encerrarOperacao.method,
                headers: [
                    ["token", localStorage.getItem("token") || ""]
                ],
                credentials: 'include'
            }).then((resp: Response) => {
                if (resp.status == 200) result = true;
            });
        return result;
    }
}

class OperacoesCards extends React.Component<any, any> {
    render() {

        const Header = () => {
            return (
                <div
                    style={{
                        height: '40px',
                    }}
                >
                </div>
            )
        }

        const Footer = () => {
            return (
                <div
                    style={{
                        //height: '400px',
                        padding: '3rem',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {(this.props.items.LastEvaluatedKey) ?
                        <IonButton onClick={this.props.loadMoreItems} color='tertiary'>Carregar mais itens</IonButton>
                        : <></>}
                </div>
            )
        }

        return (
            <>
                {
                    this.props.items.Items.length > 0 ?
                        <Virtuoso className="ion-content-scroll-host"
                            style={{ height: '100%' }}
                            overscan={1440}
                            data={this.props.items.Items}
                            /*endReached={this.props.loadMoreItems}*/
                            //itemSize={() => 280}
                            itemContent={(index: any, value: any) => (

                                //(value.dataInicio.substring(0,10) != this.props.items.Items[index-1].dataInicio.substring(0,10)?<div>cabeçalho</div>:<></>) &&
                                ((this.props.chipEmLiberacao && value.liberado == false) || (this.props.chipLiberado && value.liberado == true)) ?
                                    //(true == true) ?
                                    <>
                                        {
                                            value.status == "Concluido" ?
                                                index == 0 ?
                                                    <OperacoesCardDateGroup date={value.dataEncerramento}></OperacoesCardDateGroup> :
                                                    value.dataEncerramento.substring(0, 10) != this.props.items.Items[(index - 1 < 0) ? 0 : index - 1].dataEncerramento.substring(0, 10) ?
                                                        <OperacoesCardDateGroup date={value.dataEncerramento}></OperacoesCardDateGroup> :
                                                        <></> : <></>
                                        }
                                        {value.status == "Em andamento" ?
                                            this.props.pesquisa == "" ?
                                                < OperacoesCard item={value} indice={index} changeSemaforo={this.props.changeSemaforo} router={this.props.router} usaLiberacao={this.props.usaLiberacao} changeLiberacao={this.props.changeLiberacao} registrarAnotacao={this.props.registrarAnotacao} registrarChamada={this.props.registrarChamada} registrarEncerramento={this.props.registrarEncerramento} />
                                                :
                                                value.notasFiltro?.indexOf(this.props.pesquisa) > -1 ?
                                                    <>< OperacoesCard item={value} indice={index} changeSemaforo={this.props.changeSemaforo} router={this.props.router} usaLiberacao={this.props.usaLiberacao} changeLiberacao={this.props.changeLiberacao} registrarAnotacao={this.props.registrarAnotacao} registrarChamada={this.props.registrarChamada} registrarEncerramento={this.props.registrarEncerramento} /> </> :
                                                    <></>
                                            :
                                            < OperacoesCard item={value} indice={index} changeSemaforo={this.props.changeSemaforo} router={this.props.router} usaLiberacao={this.props.usaLiberacao} changeLiberacao={this.props.changeLiberacao} registrarAnotacao={this.props.registrarAnotacao} registrarChamada={this.props.registrarChamada} registrarEncerramento={this.props.registrarEncerramento} />
                                        }
                                        {}
                                    </> : <><p style={{fontSize:'1px'}}>&nbsp;</p>{/*bug precisa aparecer pelo menos na tela um pedacinho para o componente renderizar, se não dá erro no filtro de situaão da liberação*/}</>
                            )}
                            components={{ Header, Footer }}
                        /> :
                        <div style={{ width: '100%', textAlign: 'center' }}><br></br>Sem operações no momento</div>
                }
            </>
        )

    }
}

class OperacoesCardDateGroup extends React.Component<any, any> {
    render() {
        return (
            <IonItem lines='none' style={{ marginTop: '20px', marginBottom: '10px' }}>
                <div className={"cabecalho"} style={{ marginLeft: '10px' }} >{new Date(this.props.date).toLocaleString('pt-BR').substring(0, 10)}</div>
            </IonItem>
        )
    }
}
class OperacoesCard extends React.Component<any, any> {
    render() {

        return (
            !this.props.item.divEncerrado ?
                <div>
                    <IonItem lines='none' style={{ height: '36px', minHeight: '36px', padding: '0', margin: '0' }}>
                        <div style={{ height: '30px', minHeight: '30px', display: 'flex', width: '100%', border: '1px hidden #dddddd', borderBottom: '0px', borderRadius: '10px 10px 0px 0px', padding: '0', margin: '1' }}>
                            <div style={{ borderRadius: '10px 10px 0px 0px', marginLeft: '1px', backgroundColor: 'var(--ion-color-primary)' }}>
                                <IonLabel slot='start' style={{ maxWidth: '150px', fontSize: "14px", fontWeight: 'bold', padding: '0', margin: '5px' }}>{this.props.item.clienteNomeInterno ? this.props.item.clienteNomeInterno : this.props.item.clienteNome ? this.props.item.clienteNome : ""}</IonLabel>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }}></div>
                            <div style={{ borderRadius: '10px 10px 0px 0px', marginRight: '1px', backgroundColor: 'var(--ion-color-tertiary)' }}>
                                <IonLabel slot='end' style={{ minWidth: '120px', fontSize: "15px", fontWeight: 'bold', textAlign: 'center', padding: '0', margin: '5px', color: 'white' }}><span style={{ fontSize: '12px' }}>duração: </span>{tempoCorrido(new Date(this.props.item.dataInicio), (this.props.item.dataEncerramento ? new Date(this.props.item.dataEncerramento) : new Date()))}</IonLabel>
                            </div>
                        </div>
                    </IonItem>
                    <IonItem lines='none' style={{ paddingTop: '0px', paddingBottom: '10px' }}>
                        <div style={{ width: '100%', marginBottom: '5px', border: '1px solid #dddddd', borderTop: '0px', borderRadius: '0px 0px 10px 10px', boxShadow: '0px 1px gray', position: 'relative' }}>
                            <OperacoesCardHeader item={this.props.item} indice={this.props.indice} changeSemaforo={this.props.changeSemaforo} router={this.props.router} usaLiberacao={this.props.usaLiberacao} changeLiberacao={this.props.changeLiberacao} />

                            <OperacoesCardDetail item={this.props.item} />

                            {(this.props.item.status == "Em andamento") ?
                                (<OperacoesCardFooter item={this.props.item} indice={this.props.indice} registrarAnotacao={this.props.registrarAnotacao} registrarChamada={this.props.registrarChamada} registrarEncerramento={this.props.registrarEncerramento} router={this.props.router} />) : (<></>)}

                            {(this.props.item.divEncerrando ?
                                <div style={{ position: 'absolute', left: '0px', top: '0px', background: 'gray', height: '100%', width: '100%', zIndex: 999, borderRadius: '0px 0px 10px 10px', opacity: 0.8, paddingRight: '45px' }}>
                                    <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '30px', fontWeight: 'bold' }}>Encerrando...</span>
                                </div> : <></>)}
                        </div>
                    </IonItem>
                </div> :
                <></>
        )
    }
}

function tempoCorrido(horario: Date, horarioFim: Date) {
    var g = Math.ceil((horarioFim.getTime() - horario.getTime()) / 1000 / 60);
    if (g > 1440) {
        return Math.floor(g / 60).toString() + 'h';
    }
    else if (g > 60) {
        return Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + '';
    }
    else {
        return g.toString() + 'min';
    }
}

class OperacoesCardHeader extends React.Component<any, any> {
    render() {
        const value = this.props.item;
        const cor = this.props.item.status != "Em andamento" ? 'primary' : this.props.item.semaforo == 2 ? 'secondary' : this.props.item.semaforo == 3 ? 'tertiary' : 'primary';
        const indice = this.props.indice;

        return (
            <>
                <IonItem color={cor} style={{ width: "100%", borderRadius: "0px 0px 0 0", backgroud: '#eeeeee' }} lines="none" >
                    <IonLabel slot="start" style={{ margin: 0 }}>{value.operacaoTipoOperacao.concat(" - ", value.tipoCarga, " ")}</IonLabel>

                    {(this.props.item.status == "Em andamento" && this.props.usaLiberacao == true) ?
                        (value.atualizandoLiberacao) ?
                            <IonChip slot='start' style={{ '--background': 'gray', '--color': '#ffffff' }}>
                                <IonIcon icon={sync} ></IonIcon>
                                <IonLabel>alterando</IonLabel>
                            </IonChip> :
                            (!value.liberado) ?
                                (
                                    <IonChip slot='start' style={{ '--background': 'red', '--color': '#ffffff' }} onClick={() => this.props.changeLiberacao(indice, true)}>
                                        <IonIcon icon={pauseCircle} ></IonIcon>
                                        <IonLabel>Em liberação</IonLabel>
                                    </IonChip>) : (<IonChip slot='start' style={{ '--background': 'green', '--color': '#ffffff' }} onClick={() => this.props.changeLiberacao(indice, false)}>
                                        <IonIcon icon={playCircle} ></IonIcon>
                                        <IonLabel>Liberado</IonLabel>
                                    </IonChip>)
                        : <></>}

                    {(this.props.item.status == "Em andamento" && value.sk.startsWith("usuarios")) ? (
                        <IonLabel slot='end' title='Aberto manualmente' style={{
                            padding: '0px', height: '40px', margin: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                            marginRight: '20px',
                            fontSize: 'x-large'
                        }}>
                            <IonIcon slot='end' icon={handRight} style={{ pointerEvents: 'none' }} ></IonIcon>
                        </IonLabel>
                    ) : ('')}

                    {(this.props.item.status == "Em andamento") ?
                        (<OperacoesCardSemaforo changeSemaforo={this.props.changeSemaforo} indice={this.props.indice} atualizandoSemaforo={value.atualizandoSemaforo} />) :
                        <></>}

                    <IonButton slot='end' fill='clear' style={{ marginInlineStart: '10px' }} onClick={() => { this.props.router.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.id.replace(/#/g, '%23') }) }}>
                        <IonIcon slot="icon-only" color='light' icon={documentText} />
                    </IonButton>
                </IonItem>
            </>
        )

    }
}

class OperacoesCardSemaforo extends React.Component<any, any> {
    render() {
        const changeSemaforo = this.props.changeSemaforo;
        const indice = this.props.indice;
        const atualizandoSemaforo = this.props.atualizandoSemaforo;

        return (!atualizandoSemaforo ?
            <IonLabel slot='end' style={{
                background: '#fff', padding: '0px', height: '40px', margin: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                width: '100px'
            }}>
                <IonIcon slot='end' style={{ fontSize: "24px" }} color='primary' icon={ellipse} onClick={() => { changeSemaforo(indice, 1) }}></IonIcon>
                <IonIcon slot='end' style={{ fontSize: "24px" }} color='secondary' icon={ellipse} onClick={() => { changeSemaforo(indice, 2) }}></IonIcon>
                <IonIcon slot='end' style={{ fontSize: "24px" }} color='tertiary' icon={ellipse} onClick={() => { changeSemaforo(indice, 3) }}></IonIcon>
            </IonLabel>
            :
            <div slot='end' style={{
                background: 'gray', padding: '0px', height: '40px', margin: '0px',
                color: '#ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                width: '100px'
            }}><IonIcon icon={sync} style={{ marginLeft: '5px' }}></IonIcon><IonLabel style={{ marginLeft: '5px', marginRight: '5px' }}>alterando</IonLabel>

            </div>);
    }
}

class OperacoesCardDetail extends React.Component<any, any> {

    cpfMask(v: string) {
        v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
        return v;
    }

    render() {
        const value = this.props.item;

        return (
            <IonGrid style={{ background: '#ffffff', margin: '0px', padding: '0px' }}>
                <IonRow style={{ background: '#eeeeee' }}>
                    <IonCol style={{ textAlign: 'center', '--min-height': 'auto' }}>
                        <IonItem lines='none' style={{ '--min-height': 'auto' }} >
                            <IonIcon slot='start' src={person} ></IonIcon>
                            <IonNote slot='start' >motorista</IonNote>
                            <IonLabel >{value.motoristaNome}</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol style={{ textAlign: 'center', '--min-height': 'auto' }}>
                        <IonItem lines='none' style={{ '--min-height': 'auto' }} >
                            <IonIcon slot='start' src={card} ></IonIcon>
                            <IonNote slot='start'>cpf</IonNote>
                            <IonLabel >{value.motoristaDocumento}</IonLabel>
                        </IonItem>
                    </IonCol>
                    <IonCol style={{ textAlign: 'center', '--min-height': 'auto' }}>
                        <IonItem lines='none' style={{ '--min-height': 'auto' }} >
                            <IonIcon slot='start' src={car} ></IonIcon>
                            <IonNote slot='start'>veículo</IonNote>
                            <IonLabel >{value.veiculo.toUpperCase()}</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow style={{ justifyContent: 'left' }}>
                    <IonCol >
                        <IonItem style={{ width: "100%" }} lines="none">
                            <IonLabel style={{ margin: 0 }} >
                                <IonText color="primary"><h3>Notas Fiscais</h3></IonText>
                                {value.notasAdicionais.map((notasValue: any, index: any) => (value.notasFiltro += notasValue.notaFiscal.substr(25, 9).toString(),
                                    <p style={{ color: "#000000", display: 'flex' }}><b>{notasValue.notaFiscal ? notasValue.notaFiscal.substr(25, 9) : "Não informada"}</b> | <IonNote style={{ fontSize: '11px' }}>{notasValue.razaoSocial || "Não identificado"} | {notasValue.notaFiscal ? notasValue.notaFiscal.substr(6, 14).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : ""}</IonNote><IonBadge style={{ fontSize: '11px', marginLeft: '5px', padding: '3px', alignSelf: 'center' }} hidden={notasValue.anotacao ? false : true}>{notasValue.anotacao || ""}</IonBadge></p>
                                ))}
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                    {/*
                    <IonCol size='auto'>
                        <IonItem style={{ width: "100%" }} lines="none">
                            <IonLabel style={{ margin: 0 }} className="ion-text-wrap">
                                <IonText color="primary"><h3>Motorista</h3></IonText>
                                <p style={{ color: "#000000", fontWeight: "bold" }}>{value.motoristaNome}</p>
                                <p style={{ color: "#000000", fontWeight: "bold" }}>{this.cpfMask(value.motoristaDocumento)}</p>
                                <p style={{ color: "#000000", fontWeight: "bold" }}>Placa: {value.veiculo}</p>
                            </IonLabel>
                        </IonItem>
                    </IonCol>
                                */}
                    <IonCol style={{ minWidth: '330px' }}>
                        <IonItem style={{ width: "100%", '--min-height': 'auto' }} lines="none">
                            <IonLabel style={{ margin: 0 }} className="ion-text-wrap">
                                <IonText color="primary"><h3>Anotações</h3></IonText>
                            </IonLabel>
                        </IonItem>

                        {
                            value.anotacoes?.sort(function (a: any, b: any) {
                                if (a.data < b.data) {
                                    return 1;
                                }
                                if (a.data > b.data) {
                                    return -1;
                                }
                                // a must be equal to b
                                return 0;
                            }).slice(0, 5).map((anotacoesValue: any) => (
                                <div style={{ marginBottom: '4px', width: '100%', borderRadius: '10px' }}>

                                    <IonItem lines='none' style={{ '--min-height': 'auto', width: '100%' }} color='#green'>
                                        <IonNote>{new Date(anotacoesValue.data).toLocaleTimeString().substring(0, 5)}&nbsp;</IonNote>
                                        <IonLabel color='black' class="ion-text-wrap" style={{ margin: '0px', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif', lineHeight: '16px' }}>{anotacoesValue.anotacao}{/*<span style={{ fontSize: '10px', color: '#666', fontWeight: 'lighter' }}>{" (" + (anotacoesValue.pessoa || "") + ")"}</span>*/}</IonLabel>
                                    </IonItem>
                                    {/*
                                    <IonItem lines='none' style={{ '--min-height': 'auto', width: '100%' }}>
                                        <IonLabel color='black' style={{ margin: '0px', fontSize: '11px', fontFamily: 'Open Sans, sans-serif', lineHeight: '14px' }}>{new Date(anotacoesValue.data).toLocaleString()}</IonLabel>
                                    </IonItem>
                                    */}
                                </div>))
                        }

                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }
}

class OperacoesCardFooter extends React.Component<any, any> {
    render() {
        const value = this.props.item;

        return (
            <IonItem style={{ padding: 0, margin: 0, "borderRadius": "0px 0px 10px 10px", backgroundColor: "#eeeeee" }} color="grey" lines='none'>
                <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => {
                    this.props.registrarChamada(this.props.indice);
                }}>
                    <IonIcon icon={megaphone} style={{ padding: 0, margin: 0 }}></IonIcon>
                    <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }}>Chamar</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => {
                    this.props.registrarAnotacao(this.props.indice);
                }}>
                    <IonIcon icon={pencil} style={{ padding: 0, margin: 0 }}></IonIcon>
                    <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }}>Anotação</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton style={{ height: "25px", padding: 0, margin: 0 }} color='tertiary' onClick={(e) => { this.props.router.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: value.id.replace(/#/g, '%23') }) }}>
                    <IonIcon icon={documentText} style={{ padding: 0, margin: 0 }}></IonIcon>
                    <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }}>Detalhes</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton slot='end' style={{ height: "25px", padding: 0, margin: 0 }} color='primary' onClick={(e) => {
                    { this.props.registrarEncerramento(this.props.indice) }
                }}>
                    <IonIcon icon={power} color={"danger"} style={{ padding: 0, margin: 0 }}></IonIcon>
                    <IonLabel style={{ "fontSize": "11px", padding: 0, margin: 0 }}>Encerrar</IonLabel>
                </IonSegmentButton>

            </IonItem>)
    }
}

export default OperacoesList;