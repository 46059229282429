import { IonToast, IonTabs, IonPage, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterOutlet, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';
import Conta from './pages/Conta/Conta';
import ContaAlt from './pages/ContaAlt/ContaAlt';
import Instrucoes from './pages/Instrucoes/Instrucoes';
import TermosPolitica from './pages/TermosPolitica/TermosPolitica';
import Qrcode from './pages/Qrcode/Qrcode';
import Acompanhar from './pages/Acompanhar/Acompanhar';
import "./Home.css"
import { calendar, flag, handRight, list, people, person, podium } from 'ionicons/icons';
import Operacoes from './pages/Operacoes/Operacoes';
import Equipe from './pages/Equipe/Equipe';
import EquipeCad from './pages/EquipeCad/EquipeCad';
import Agendamentos from './pages/Agendamentos/Agendamentos';
import AgendamentosCad from './pages/AgendamentosCad/AgendamentosCad';
import Relatorios from './pages/Relatorios/Relatorios';
import Relatorio from './pages/Relatorio/Relatorio';
import OperacoesNovo from './pages/Operacoes/OperacoesNovo';
import ValidarTokenMotorista from './pages/parceiro/ValidarTokenMotorista/ValidarTokenMotorista';
import Historico from './pages/parceiro/Historico/Historico';
import ContaParceiro from './pages/parceiro/ContaParceiro/ContaParceiro';
import RelatorioAgendamentos from './pages/RelatorioAgendamentos/RelatorioAgendamentos';
import Painel from './pages/Painel/Painel';
import Monitor from './pages/Monitor/Monitor';
import RelatorioBoleto from './pages/RelatorioBoleto/RelatorioBoleto';

const Home: React.FC<RouteComponentProps> = ({ match }) => {

  const [categoria, setCategoria] = useState("");
  const [stateHideAgendamento, setStateHideAgendamento] = useState(true);
  const [stateLoadMonitor, setStateLoadMonitor] = useState(false);
  const htmlEl = document.querySelector('html');

  useIonViewWillEnter(()=>{
    // utilizado para estabelecer nova cor de acordo com o cliente.
    // Ainda não implementado pois não foi pedido por nenhum cliente
    //htmlEl?.style.setProperty('--ion-color-primary', '#fff200');
    //htmlEl?.style.setProperty('--ion-color-secondary', '#00358e');
  });

  useEffect(() => {
    setCategoria(window.localStorage.getItem("categoria") || "");

    let acessos = JSON.parse(window.localStorage.getItem("acessos") || "");
    if (acessos.agendamento == 'leitura' || acessos.agendamento == 'edicao') {
      setStateHideAgendamento(false);
    }

    if (acessos.hasOwnProperty('monitor')) {
      setStateLoadMonitor(true);
      //console.log("monitor",acessos.hasOwnProperty('monitor'));
    }

    //console.log("acessos",acessos);

    //console.log("categoria", categoria);
  }, []);

  return (
    <IonTabs>
      <IonRouterOutlet >
        <Route path={`${match.url}/operacoes`} component={Operacoes} exact />
        <Route path={`${match.url}/operacoesNovo`} component={OperacoesNovo} exact />
        <Route path={`${match.url}/operacoes/acompanhar`} component={Acompanhar} exact />
        <Route path={`${match.url}/equipe`} component={Equipe} exact />
        <Route path={`${match.url}/equipecad`} component={EquipeCad} exact />
        <Route path={`${match.url}/agendamentos`} component={Agendamentos} exact />
        <Route path={`${match.url}/agendamentoscad`} component={AgendamentosCad} exact />
        <Route path={`${match.url}/relatorio`} component={Relatorio} exact />
        <Route path={`${match.url}/relatorioboleto`} component={RelatorioBoleto} exact />
        <Route path={`${match.url}/relatorioagendamentos`} component={RelatorioAgendamentos} exact />
        <Route path={`${match.url}/relatorios`} component={Relatorios} exact />
        <Route path={`${match.url}/painel`} component={Painel} exact />
        <Route path={`${match.url}/monitor`} component={Monitor} exact />

        <Route path={`${match.url}/conta`} component={Conta} exact />
        <Route path={`${match.url}/contaalt`} component={ContaAlt} exact />
        <Route path={`${match.url}/termospolitica`} component={TermosPolitica} exact />
        <Route path={`${match.url}/instrucoes`} component={Instrucoes} exact />
        <Route path={`${match.url}/qrcode`} component={Qrcode} exact />

        <Route path={`${match.url}/validartokenmotorista`} component={ValidarTokenMotorista} exact />
        <Route path={`${match.url}/historico`} component={Historico} exact />
        <Route path={`${match.url}/contaparceiro`} component={ContaParceiro} exact />

        <Route exact path={match.url} render={() => <Redirect to={stateLoadMonitor ? `${match.url}/monitor` : window.localStorage.getItem("operacoesNovo") == '1' ? `${match.url}/operacoesNovo` : `${match.url}/operacoes`} />} />
      </IonRouterOutlet>
      {(categoria == "parceiro") ? (
        <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
          <IonTabButton href={`${match.url}/validartokenmotorista`} tab="validartokenmotorista">
            <IonIcon icon={handRight}></IonIcon>
            <IonLabel>Validar</IonLabel>
          </IonTabButton>
          <IonTabButton href={`${match.url}/historico`} tab="historico">
            <IonIcon icon={list}></IonIcon>
            <IonLabel>Histórico</IonLabel>
          </IonTabButton>
          <IonTabButton href={`${match.url}/equipe`} tab="equipe">
            <IonIcon icon={people}></IonIcon>
            <IonLabel>Equipe</IonLabel>
          </IonTabButton>
          <IonTabButton href={`${match.url}/contaparceiro`} tab="contaparceiro">
            <IonIcon icon={person}></IonIcon>
            <IonLabel>Conta</IonLabel>
          </IonTabButton>
        </IonTabBar>
      ) : (stateHideAgendamento) ?
        (
          <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
            <IonTabButton href={window.localStorage.getItem("operacoesNovo") == '1' ? `${match.url}/operacoesNovo` : `${match.url}/operacoes`} tab="operacoes">
              <IonIcon icon={flag}></IonIcon>
              <IonLabel>Operações</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/equipe`} tab="equipe">
              <IonIcon icon={people}></IonIcon>
              <IonLabel>Equipe</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/relatorios`} tab="relatorios">
              <IonIcon icon={podium}></IonIcon>
              <IonLabel>Relatórios</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/conta`} tab="conta">
              <IonIcon icon={person}></IonIcon>
              <IonLabel>Conta</IonLabel>
            </IonTabButton>
          </IonTabBar>
        ) :
        (
          <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
            <IonTabButton href={window.localStorage.getItem("operacoesNovo") == '1' ? `${match.url}/operacoesNovo` : `${match.url}/operacoes`} tab="operacoes">
              <IonIcon icon={flag}></IonIcon>
              <IonLabel>Operações</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/agendamentos`} tab="agendamentos" >
              <IonIcon icon={calendar}></IonIcon>
              <IonLabel>Agenda</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/equipe`} tab="equipe">
              <IonIcon icon={people}></IonIcon>
              <IonLabel>Equipe</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/relatorios`} tab="relatorios">
              <IonIcon icon={podium}></IonIcon>
              <IonLabel>Relatórios</IonLabel>
            </IonTabButton>
            <IonTabButton href={`${match.url}/conta`} tab="conta">
              <IonIcon icon={person}></IonIcon>
              <IonLabel>Conta</IonLabel>
            </IonTabButton>
          </IonTabBar>
        )}
    </IonTabs>
  );
};

export default Home;

